<template>
  <div class="d-flex flex-nowrap">
    <document-ref-icon
      :visible="showIcon"
      :icon-name="iconName"
      :mixed="mixed"
    />
    <div class="d-flex flex-column flex-nowrap">
      <v-chip
        class="label-ref"
        :style="style"
        :color="_color"
        :close="close"
        small
        @click:close="propagateClose"
      >
        <span
          v-if="groupId"
          class="grouped"
        >
          <span
            class="group"
            :style="textColor"
          >{{groupName}}</span>
          <span class="name">{{name}}</span>
        </span>

        <span
          :style="textColor"
          class="ungrouped"
          v-else
        >{{name}}</span>
      </v-chip>
      <div class="mt-1">
        <slot name="doc-type" />
      </div>

    </div>
  </div>
</template>

<script>

import ClientCache from '@/mixins/client-cache'
import themeController from '@/themeController'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon')
  },
  computed: {
    textColor () {
      const e = document.createElement('div')
      e.style.color = this._color
      document.body.appendChild(e)
      const rgb = window.getComputedStyle(e).color.match(/\d+/g).map(function (a) { return parseInt(a, 10) })
      document.body.removeChild(e)
      const hex = '#' + (((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).substr(1))
      const c = themeController.getLightness(hex) === 0 ? 'black' : 'white'
      return `color: ${c};`
    },
    _color () {
      return this.color || this.cache?.doc?.color
    },
    cacheType () {
      return ClientCache.CacheType.LabelRef
    },
    groupId () {
      return this.cache?.doc?.group?.id
    },
    groupName () {
      return this.cache?.doc?.group?.name
    },
    name () {
      return this.id ? this.cache?.doc?.name : this.$t('t.None')
    },
    style () {
      const dark = this.$vuetify.theme.isDark
      return {
        '--bg-color': dark ? '#000000EE' : '#FFFFFFEE',
        '--color': dark ? 'white' : 'black',
        '--padding': this.close ? '0 1em 0 0' : '0'
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    propagateClose (e) {
      this.$emit('click:close', e)
    }
  },
  mixins: [ClientCache],
  props: {
    color: String,
    close: Boolean,
    id: [Number, String],
    mixed: Boolean,
    showIcon: Boolean,
    iconName: String
  }
}
</script>

<style lang="stylus" scoped>
.grouped
  .name
    white-space nowrap
    background-color var(--bg-color)
    opacity 0.75
    color var(--color)
    padding 0.2em 0.4em
    margin-right 3px
    border-radius var(--border-radius-right) !important

.group
  padding 0.2em 0.5em
  white-space nowrap

.ungrouped
  padding 0.2em 0.5em
  white-space nowrap

.label-ref
  // padding var(--padding)
  // border-radius var(--border-radius) !important
  flex-basis auto
  flex-grow 0
</style>
